<!-- 商品新增组件 -->
<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <span class="place-orderInfo-title">商品</span>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="alertAdd = true">添加</el-button>
      </el-form-item>
      <el-form-item style="margin-left:30px">
        <el-radio-group v-model="moneyType" @change="changeCommodityList">
          <el-radio label="1">按瓶</el-radio>
          <el-radio label="2">按重量</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <!-- 表格区域 -->
    <div class="table-box">
      <el-table ref="mainTable" highlight-current-row border :data="upData.Detail" height="100%" v-loading="tableLoading">
        <el-table-column width="80" label="序号" type="index" align="center"></el-table-column>
        <el-table-column label="商品名称" show-overflow-tooltip="" prop="Name"></el-table-column>
        <el-table-column v-if="type !== 'special'" label="商品分类" prop="ClassifyName"></el-table-column>
        <el-table-column :label="$route.params.type == 'wxsl' && upData.OrderType == 5 ? '单价（积分）' : '单价（元）'">
          <template v-slot="scope">
            <el-input
              v-if="moneyType == '1'"
              maxLength="100"
              :readonly="inputRedonly"
              @input="setPrice(scope, scope.$index)"
              oninput="value=value.replace(/[^-\d.]/g,[])"
              v-model="scope.row.Price"
              placeholder="输入单价"
              clearable
            ></el-input>
            <el-input
              v-else
              :readonly="inputRedonly"
              maxLength="100"
              @input="setPrice(scope, scope.$index)"
              oninput="value=value.replace(/[^-\d.]/g,[])"
              v-model="scope.row.WeightPrice"
              placeholder="输入单价"
              clearable
            ></el-input>
          </template>
        </el-table-column>
        <template v-if="moneyType != '1'">
          <el-table-column label="瓶数">
            <template v-slot="scope">
              <el-input :readonly="inputRedonly" maxLength="10" @input="setMoney(scope)" oninput="value=value.replace(/[^\d]/g,[])" v-model="scope.row.CylinderCount" clearable></el-input>
            </template>
          </el-table-column>
          <el-table-column width="150" label="重量">
            <template v-slot="scope">
              <el-input-number
                :readonly="inputRedonly"
                style="width:100%"
                v-model="scope.row.Quantity"
                controls-position="right"
                :precision="2"
                :step="0.1"
                :max="10000"
                @change="setMoney(scope)"
                clearable
              ></el-input-number>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column label="瓶数">
            <template v-slot="scope">
              <el-input :readonly="inputRedonly" maxLength="10" @input="setMoney(scope)" oninput="value=value.replace(/[^\d]/g,[])" v-model="scope.row.Quantity" clearable></el-input>
            </template>
          </el-table-column>
        </template>

        <!-- <el-table-column width="150" v-if="type !== 'special'" :label="moneyType == '1' ? '数量' : '重量（kg)'" prop="Quantity">
          <template v-slot="scope">
            <el-input v-if="moneyType == '1'" maxLength="10" @input="setMoney(scope)" oninput="value=value.replace(/[^\d]/g,[])" v-model="scope.row.Quantity" clearable></el-input>
      
            <el-input-number
              v-else
              style="width:100%"
              v-model="scope.row.Quantity"
              controls-position="right"
              :precision="2"
              :step="0.1"
              :max="100"
              @change="setMoney(scope)"
              clearable
            ></el-input-number>
          </template>
        </el-table-column> -->
        <el-table-column width="120" v-if="type !== 'special'" :label="$route.params.type == 'wxsl' && upData.OrderType == 5 ? '金额（积分）' : '金额（元）'" prop="Money">
          <template v-slot="scope">
            <span v-if="moneyType == '1'">
              {{ ((scope.row.Price || 0) * scope.row.Quantity).toFixed(2) }}
            </span>
            <span v-else>
              {{ ((scope.row.WeightPrice || 0) * scope.row.Quantity).toFixed(2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" class-name="table-opt" fixed="right">
          <template v-slot="scope">
            <el-tag color="#f56c6c" effect="dark" size="mini" @click="handleDel(scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 商品新增弹窗 -->
    <XkMaxDialog :dialogVisible.sync="alertAdd" :title="`商品添加`" :getIsfull="false" :titleVisible.sync="alertAdd">
      <template #content>
        <CommodityAddition :moneyType="moneyType" :cusId="cusId" @addInfo="addInfo" @closeInfo="alertAdd = false"></CommodityAddition>
      </template>
    </XkMaxDialog>
  </div>
</template>

<script>
import CommodityAddition from '@/compPages/CommodityAddition';
import { getConfigList } from '@/api/sysConfig';
export default {
  name: 'ProductAddition',
  components: {
    CommodityAddition,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    type: {
      //控制列表的显示项
      type: String,
      default: '',
    },
    cusId: {
      type: String,
      default: '',
    },
  },
  watch: {
    formData: {
      handler(n, o) {
        this.upData = Object.assign({}, this.formData);
        this.inputRedonly = this.upData.OrderType != 1 && this.$route.params.type == 'wxsl' ? true : false;
        this.moneyType = this.upData.PurchType == 1 ? '2' : '1';
        this.upData.Detail.forEach((item, index) => {
          // 利用绑定的下标 修改对应的金额级数量
          item.xkIndex = index;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      inputRedonly: false,
      moneyType: '1',
      alertAdd: false, //商品新增
      tableLoading: false,
      upData: {
        CusId: '', //燃气用户id
        CusName: '', //燃气用户
        CusNumber: '', //客户证号
        OrderType: 1, //默认货到付款
        OrderPhone: '', //下单电话
        PurchType: 0,
        CusStationId: '', //燃气用户所属站点Id
        CusStation: '', //燃气用户所属站点

        Receptor: '', //收货人
        CusStationId: '', //所属站点ID

        ReceptPhone: '', //收货电话
        ReceptAddress: '', //收货地址

        Money: '', //金额

        OperatorId: '', //配送员Id
        Operator: '', //:配送员
        Detail: [], //订单详情商品
        Receptor: '',
        ReceptPhone: '',
        ReceptAddress: '',
        ReserveBeginTime: '',
        ReserveEndTime: '',
      },
    };
  },
  created() {
    this.geStyleCss();
  },
  methods: {
    changeCommodityList() {
      if (this.upData.Detail.length > 0) {
        this.$confirm('现在切换类型会导致以选择列表被清空，确认切换吗？')
          .then((data) => {
            this.upData.Detail = [];
            this.getAllMoney();
            // this.$emit('childrenData', this.upData);
          })
          .catch((data) => {
            this.moneyType = this.moneyType == '1' ? '2' : '1';
          });
      }
    },
    async geStyleCss() {
      let res = await getConfigList();
      if (res.Success) {
        let resData = res.Data.filter((data) => data.Key == 'WebOrderMode');
      }
    },
    addInfo(val) {
      this.tableLoading = true;
      const upDataInfoTable = this.upData.Detail;
      // 没有选择商品直接添加
      if (upDataInfoTable.length == 0) {
        this.upData.Detail = val;
      } else {
        // 过滤数据，如果有同id 数据不再添加
        this.upData.Detail = this.upData.Detail.concat(val);
        //定义一个空数组
        var arr5 = [];
        //遍历数组
        for (var i = 0; i < this.upData.Detail.length; i++) {
          //定义一个数组用来临时存放合并后的数组元素
          var tmer = this.upData.Detail[i];
          var flag = true;
          for (var j = 0; j < arr5.length; j++) {
            //判断
            if (tmer.Id == arr5[j].Id) {
              flag = false;
              break;
            }
          }
          //如果flag的值为true，则在arr5数组元素后添加元素
          if (flag) {
            arr5.push(tmer);
          }
        }
        this.upData.Detail = arr5;
      }
      this.upData.Detail = JSON.parse(JSON.stringify(this.upData.Detail));
      this.upData.Detail.forEach((item, index) => {
        this.upData.Detail[index] = {
          Id: item.Id, // 产品Id
          xkIndex: index, // 用作判断 金额修改的下标
          ProductId: item.Id, // 产品Id
          Name: item.Name, //产品名
          ProductName: item.Name, //产品名
          ClassifyId: item.ClassifyId, // 商品分类Id
          ClassifyName: item.ClassifyName, // 商品分类名称
          Price: item.Price, // 单价
          WeightPrice: item.WeightPrice, // 重量单价
          Quantity: 1, //数量默认1
          CylinderCount: 1, //默认瓶数1
          Money: this.moneyType == '1' ? item.Price : item.WeightPrice, // 金额
        };
      });
      this.getAllMoney();
      this.alertAdd = false;
      this.tableLoading = false;
    },
    // 计算所有商品总额
    getAllMoney() {
      this.upData.Money = 0;
      this.upData.PurchType = this.moneyType == '1' ? 0 : 1;
      this.upData.Detail.forEach((item, index) => {
        this.upData.Money = this.upData.Money + parseFloat(item.Money ? item.Money : 0);
      });
      this.upData.Money = (this.upData.Money - (this.upData.CouponMoney || 0)).toFixed(2);
      this.$emit('childrenData', this.upData);
    },
    handleDel(val) {
      this.upData.Detail.forEach((item, index) => {
        if (item.Id == val.Id) {
          this.upData.Detail.splice(index, 1);
        }
      });
      this.getAllMoney();
    },
    //金额合计
    setMoney(val) {
      this.upData.Money = 0;
      this.upData.Detail.forEach((item, index) => {
        // 利用绑定的下标 修改对应的金额级数量
        if (item.xkIndex == val.row.xkIndex) {
          this.upData.Detail[index].Quantity = Number(val.row.Quantity);
          if (this.moneyType == '1') {
            this.upData.Detail[index].Money = val.row.Quantity * val.row.Price;
          } else {
            this.upData.Detail[index].Money = val.row.Quantity * val.row.WeightPrice;
          }
        }
        let theMoney = this.upData.Money + parseFloat(item.Money ? item.Money : 0) - (this.upData.CouponMoney || 0);
        this.upData.Money = theMoney.toFixed(2);
      });
      this.getAllMoney();
    },
    // 设置单价
    setPrice(val, index) {
      // let str = this.upData.Detail[index].Price;
      // let sta = this.upData.Detail[index].WeightPrice;
      // this.upData.Detail[index].Price = this.isNumeric(str) ? str.trim() : 0;
      // this.upData.Detail[index].WeightPrice = this.isNumeric(sta) ? sta.trim() : 0;

      this.setMoney(val);
    },
    isNumeric(str) {
      return /^[0-9\-\.]+$/.test(str);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-box {
  height: calc(100vh - 590px);
  margin-bottom: 10px;
}
</style>
