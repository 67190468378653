<template>
  <!-- 下单-客户查询 -->
  <div class="dialog-add" :style="{ height: getHeight }">
    <!-- 查询区域 -->
    <el-form :inline="true" class="set-form-style">
      <el-form-item label="客户名称" width="10%">
        <el-input maxLength="100" v-model="queryData.Name" clearable placeholder="请输入客户名称"></el-input>
      </el-form-item>

      <el-form-item label="客户电话">
        <el-input maxLength="100" v-model="queryData.Phone" clearable placeholder="请输入客户电话"></el-input>
      </el-form-item>
      <el-form-item label="客户身份证">
        <el-input maxLength="100" v-model="queryData.IdentityNo" clearable placeholder="请输入客户身份证"></el-input>
      </el-form-item>
      <el-form-item label="客户证号">
        <el-input maxLength="100" v-model="queryData.Number" clearable placeholder="请输入客户证号"></el-input>
      </el-form-item>
      <el-form-item label="用气地址">
        <el-input maxLength="100" v-model="queryData.GasAddress" clearable placeholder="请输入用气地址"></el-input>
      </el-form-item>
      <el-form-item class="search-btn">
        <el-button type="primary" round icon="el-icon-search" @click="query">查询客户 </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格区域 -->
    <el-table ref="mainTable" highlight-current-row border :data="tableData" height="70%" v-loading="tableLoading">
      <el-table-column width="80" label="序号" prop="RowIndex" align="center"></el-table-column>
      <el-table-column width="100" label="客户证号" prop="Number"></el-table-column>
      <el-table-column show-overflow-tooltip width="180" label="用气地址" prop="GasAddress"></el-table-column>
      <el-table-column show-overflow-tooltip width="120" label="客户姓名" prop="Name"> </el-table-column>
      <el-table-column show-overflow-tooltip label="状态" prop="Valid">
        <template #default="{row}">
          <span v-if="!row.Valid" style="color:red">已删除</span>
          <span v-else style="color:#52c41a">正常</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="客户身份证" prop="IdentityNo"></el-table-column>
      <el-table-column show-overflow-tooltip width="100" label="联系电话" prop="Phone"></el-table-column>
      <el-table-column show-overflow-tooltip label="所属站点" prop="StationName"></el-table-column>
      <el-table-column label="操作" width="120" class-name="table-opt" fixed="right">
        <template v-slot="scope">
          <!-- planOrderPermission.indexOf('来电下单按钮') != -1 -->
          <el-link v-if="scope.row.Valid" type="primary" size="mini" class="opt-item" @click="getItemInfo(scope.row)">选择</el-link>
          <el-link v-if="!scope.row.Valid && permissionBtn('下单用户恢复')" type="danger" size="mini" class="opt-item" @click="resItemInfo(scope.row)">恢复</el-link>
          <XkConfirmButton v-if="scope.row.Valid && permissionBtn('下单用户删除')" :btnLink="true" type="danger" @click="handleDel(scope.row)" :loading="row._loading" />
        </template>
      </el-table-column>
    </el-table>
    <!-- 页码 -->

    <el-form class="mt-10">
      <el-col :span="20">
        <el-form-item align="left">
          <XkPagination class="set-form-style" :total="total" :limit="comPageSize" :page="queryData.pageIndex" @pagination="pagination" />
        </el-form-item>
      </el-col>
      <!-- <el-col :span="4">
        <el-form-item align="right">
        <el-button round @click="close">取消</el-button>
      </el-form-item>
      </el-col> -->
    </el-form>
  </div>
</template>

<script>
import XkDropDown from '@/components/DropDown/XkDropDown';
import { getListForOrder, getCustomerget, postRevover, postCustomerDelete } from '@/api/customer'; //客户列表查询
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'safetyInspection',
  data() {
    return {
      alertSee: false, //查看安检信息弹窗
      alertImageSee: false, // 查看安检图片弹窗
      // 查询参数
      queryData: {
        pageIndex: 1,
        Phone: '',
        Name: '',
        PageSize: 20,
      },
      tableLoading: false,
      // 表格数据
      tableData: [],
      // 表格数据总条数
      total: 0,
    };
  },
  props: {
    getHeight: {
      type: String,
      default: '100vh',
    },
    getCustomerType: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['planOrderPermission']),
    permissionBtn: function() {
      // 判断如果是下单页面， 判断是否有删除，恢复，选择等权限
      return function(text) {
        // if (this.$route.params.type == 'web') {
        return this.planOrderPermission.indexOf(text) == -1 ? false : true;
        // } else {
        //   return true;
        // }
      };
    },
  },
  components: {
    XkDropDown, // 通用下拉框选择
  },
  created() {
    this.queryData.Phone = this.phone;
    this.queryData.Name = this.name;
    // this.queryData.PageSize = this.comPageSize;
    this.getPageList();
  },
  methods: {
    // 删除
    async handleDel(params) {
      let res = await postCustomerDelete({ Id: params.Id });
      if (res.Success) {
        this.$message({
          message: res.Msg || '操作成功',
          type: 'success',
        });
        // 删除成功，重新加载列表
      } else {
        this.$message({
          message: res.Msg || '操作失败',
          type: 'warning',
        });
      }
      this.getPageList();
    },
    // element 无限滚动加载数据
    load() {
      this.queryData.pageIndex++;
      this.getPageList();
    },
    //翻页
    pagination(params) {
      this.queryData.PageSize = params.comPageSize;
      this.queryData.pageIndex = params.pageCurrent;
      this.getPageList();
    },
    // 获取表格数据
    async getPageList() {
      this.tableLoading = true;
      try {
        let res = await getListForOrder(this.queryData);

        this.tableData = res.Data.Data;
        this.total = res.Data.Count;
        if (this.tableData.length == 1 && this.getCustomerType == '') {
          // this.$emit('getData', this.tableData[0]);
          // 如果这条是已删除用户，不默认选中
          if (this.tableData[0].Valid) {
            this.getItemInfo({ Id: this.tableData[0].Id });
          }
        }
        if (this.tableData.length == 0 && this.getCustomerType == '') {
          this.$emit('getData', { Phone: this.phone });
        }
      } catch (error) {}
      this.tableLoading = false;
    },
    // 恢复
    async resItemInfo(params) {
      try {
        let res = await postRevover({ Ids: [params.Id] });
        if (res?.Success) {
          this.$message.success('恢复成功');
          this.query();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getItemInfo(val) {
      let res = await getCustomerget({ id: val.Id });

      if (res?.Success) {
        // this.$store.dispatch('user/getOrderInfoData',res.Data) //store 暂存数据
        if (!this.getCustomerType) {
          this.$store.dispatch('user/getOrderInfoData', res.Data); //store 暂存数据
        }
        this.$emit('getData', res.Data);
      }
    },
    // 查询
    query() {
      this.tableData = [];
      this.queryData.pageIndex = 1;
      this.getPageList();
    },
    close() {
      this.$emit('closeInfo', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-add {
  width: 80%;
  margin: 0 auto;
}

::v-deep .set-form-style .el-input__inner {
  background-color: #fff !important;
  border-color: #c1c1c1 !important;
  color: #333 !important;
}
::v-deep .el-form-item__label {
  color: #31343c !important;
}
</style>
