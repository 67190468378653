import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/CylinderPledge/`

// 押瓶退瓶

// 列表
export const getCylinderPledgeList = (params, config = {}) => {
    return request.get(apiPrefix + 'List', { params: params, ...config })
}
// 新增
export const postCylinderPledgeAdd = (params, config = {}) => {
    return request.post(apiPrefix + 'Add', params, config)
}  
// 删除
export const postCylinderPledgeDelete = (params, config = {}) => {
    return request.delete(apiPrefix + 'Delete', { params: params, ...config })
}  

// 修改
export const postCylinderPledgeUpdata = (params, config = {}) => {
    return request.put(apiPrefix + 'Update', params, config)
}  
 // 新增退瓶
export const postCylinderCreateReturn = (params, config = {}) => {
    return request.post(apiPrefix + 'CreateReturn', params, config)
}  
// 获取退瓶明细
 export const getCylinderGetReturnDetail = (params, config = {}) => {
    return request.get(apiPrefix + 'GetReturnDetail', {params:params, ...config})
}  

 // 气瓶规格列表
 export const getCylinderPledgePriceList = (params, config = {}) => {
    return request.get(apiPrefix + 'GetCylinderPledgeSpecList', {params:params, ...config})
}  
// 押瓶价格列表
export const getCylinderPledgeSpecList = (params, config = {}) => {
    return request.get(apiPrefix + 'GetCylinderPledgeSpecList', {params:params, ...config})
}
// 押瓶价格明细
export const getCylinderPledgeSpecDetail = (params, config = {}) => {
    return request.get(apiPrefix + 'GetCylinderPledgeSpecDetail', {params:params, ...config})
}  
// 修改押瓶价格
export const getUpdateCylinderPledge = (params, config = {}) => {
    return request.put(apiPrefix + 'UpdateCylinderPledge', params, config)
}

// 租金列表
export const getRentPriceList = (params, config = {}) => {
    return request.get(apiPrefix + 'GetRentPriceList', { params: params, ...config });
};

// 租金明细
export const getRentPriceDetail = (params, config = {}) => {
    return request.get(apiPrefix + 'GetRentPriceDetail', { params: params, ...config });
};

// 上交押金
export const receiveRental = (params, config = {}) => {
    return request.put(apiPrefix + 'ReceiveRental', params, config)
}

// 获取押瓶单子的退瓶子信息
export const getQueryCylinderPledgeRetunInfo = (params, config = {}) => {
    return request.get(apiPrefix + 'QueryCylinderPledgeRetunInfo', {params:params, ...config})
}

// 获取押瓶单子的押瓶子信息
export const getQueryCylinderPledgeInfo = (params, config = {}) => {
    return request.get(apiPrefix + 'QueryCylinderPledgeInfo', {params:params, ...config})
}
// 新增续租
export const postCylinderPledgeRenewalCreate = (params, config = {}) => {
    return request.post(apiPrefix + 'CylinderPledgeRenewalCreate', params, config)
}
// 获取押瓶单子的续租信息
export const getQueryCylinderPledgeRenewalInfo = (params, config = {}) => {
    return request.get(apiPrefix + 'QueryCylinderPledgeRenewalInfo', {params:params, ...config})
}