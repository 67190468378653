<!--
行政区划下拉框动态加载组件
-->
<template>
  <el-cascader ref="division" :options="options" v-model="values" :props="defaultProps" placeholder="请选择区划" @change="handleChange" style="width:100%" v-loading="loading" v-if="flag" clearable>
  </el-cascader>
</template>

<script>
import { getDivisionList } from '@/api/common';
export default {
  name: 'DropdownDivision',
  // props: ['type', 'areaCode', 'setMultiple'],
  props: {
    areaCode: {
      type: Array | String,
      default: '',
    },
    type: {
      type: String,
      default: 'add',
    },
    setMultiple: {
      type: Boolean,
      default: false,
    },
    setLevel: {
      type: Number | String,
      default: 3,
    },
  },
  watch: {
    areaCode: {
      handler: function(val) {
        console.log('watch areaCode!!', val);
        this.values = val;
        if (val.length > 0) {
          let codes = ['', ...Array.from(new Set(this.values.flat()))];
          let queue = codes.map((item) => {
            return this.getData(item);
          });
          let result = [];
          Promise.all(queue).then((res) => {
            res.forEach((i) => {
              result.push(...i);
            });
            this.options = this.buildTree(result);
          });
          // this.flag = false;
          // setTimeout(() => {
          //   this.flag = true;
          // }, 1000);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      options: [],
      values: [],
      RegionCode: '',
      defaultProps: {
        lazy: true,
        lazyLoad: this.cascaderLazyLoad,
        checkStrictly: true,
        multiple: this.setMultiple,
      },
      loading: false,
      flag: true,
    };
  },
  methods: {
    //动态加载
    async cascaderLazyLoad(node, resolve) {
      //  console.log('动态加载');
      if (!node) {
        return false;
      }
      const { level } = node;
      this.RegionCode = node.value;
      let params;
      if (this.RegionCode) {
        params = {
          id: this.RegionCode,
        };
      }
      let res = await getDivisionList(params);
      if (res.code == 1) {
        if (res.Data.length > 0) {
          this.loading = false;
          resolve(
            res.Data.map((val) => {
              return {
                value: val.Id,
                label: val.Name,
                leaf: level >= this.setLevel, //因为省市区三项，所以第三次点击就不用在加载了，所以 >=2
              };
            })
          );
        } else {
          //无数据
          console.log('[]:', this.$refs['division'].getCheckedNodes());
          resolve();
          // resolve([
          //   {
          //     value: node.value,
          //     label: '其他',
          //     leaf: true,
          //   },
          // ]);
        }
      }
    },
    handleChange(value) {
      const obj = this.$refs['division'].getCheckedNodes();
      if (this.setMultiple) {
        this.$emit('region', this.values);
        return;
      }
      if (obj.length > 0) {
        const region = obj[0].data;
        this.$emit('region', region, obj[0].pathLabels);
      } else {
        this.$emit('region', '');
      }
    },
    async getData(code) {
      let res = await getDivisionList({
        id: code,
      });
      if (res.code == 1) {
        return res.Data.map((val) => {
          return {
            value: val.Id,
            label: val.Name,
            parentId: code,
          };
        });
      }
    },
    buildTree(arr) {
      const nodeMap = {};
      const tree = [];
      arr.forEach((node) => {
        nodeMap[node.value] = { ...node, children: [] }; // 复制并添加children属性
        if (!node.parentId) {
          tree.push(nodeMap[node.value]);
        }
      });
      arr.forEach((node) => {
        if (node.parentId) {
          const parent = nodeMap[node.parentId];
          if (parent) {
            parent.children.push(nodeMap[node.value]);
          }
        }
      });
      return tree;
    },
  },
  async created() {
    if (this.type == 'edit') {
      this.loading = true;
    }
  },
  mounted() {},
};
</script>

<style scoped="scoped">
.region-drop-down {
  width: 100%;
}
:v-deep .el-cascader {
  width: 100%;
}
</style>
